import React, { useState } from "react";

import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

const label = { inputProps: { "aria-label": "Switch demo" } };

const WallConfigurator = () => {
  const [formData, setFormData] = useState({
    Aantal_panelen_B: 5,
    Aantal_panelen_L: 3,
    Frame_H: 2010,
    Frame_B: 400,
    Frame_L: 1200,
  });

  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("INITIAL"); // INITIAL , LOADING , DONE

  const handleSubmit = async (e) => {
    setStatus("LOADING");
    e.preventDefault();

    const requestBody = {
      name: "ST" + Math.floor(Math.random() * 999999),
      model: "Voorbeeld_G4_01",
      type: "G4",
      parameters: [
        {
            name: "Aantal_panelen_B",
            value: formData.Aantal_panelen_B,
          },
          {
            name: "Aantal_panelen_L",
            value: formData.Aantal_panelen_L,
          },
          {
            name: "Frame_H",
            value: formData.Frame_H,
          },
          {
            name: "Frame_B",
            value: formData.Frame_B,
          },
          {
            name: "Frame_L",
            value: formData.Frame_L,
          },
      ]
    };

    console.log(requestBody);
    //return;

    try {
      const response = await fetch(
        "https://us-central1-poc-wandshop.cloudfunctions.net/staalframe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const url = await data.url;
        console.log(url);
        setUrl(url);
        setStatus("DONE");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  return (
    <div class="container">
      <div class="form-container">
        <h1>POC Steel Wall Configurator - API Visual Demo</h1>

        <form onSubmit={handleSubmit}>
        <div className="section">
            <h2>Parameters</h2>

              <div className="form-group">
                <label htmlFor="wall-height">Aantal panelen:</label>
                <input
                  type="number"
                  id="Aantal_panelen_L"
                  name="Aantal_panelen_L"
                  value={formData.Aantal_panelen_L}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>


          <div className="section">
            <h2>Paneel parameters</h2>

            <div className="form-group">
                <label htmlFor="wall-width">Aantal frames:</label>
                <input
                  type="number"
                  id="Aantal_panelen_B"
                  name="Aantal_panelen_B"
                  value={formData.Aantal_panelen_B}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="wall-width">Frame Hoogte</label>
                <input
                  type="number"
                  id="Frame_H"
                  name="Frame_H"
                  value={formData.Frame_H}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="wall-height">Frame Breedte:</label>
                <input
                  type="number"
                  id="Frame_B"
                  name="Frame_B"
                  value={formData.Frame_B}
                  onChange={handleChange}
                  required
                />
                </div>
             {/*    
            <div className="form-group">
                <label htmlFor="wall-height">Frame Lengte:</label>
                <input
                  type="number"
                  id="Frame_L"
                  name="Frame_L"
                  value={formData.Frame_L}
                  onChange={handleChange}
                  required
                />
            </div>
            */}
          </div>

          <button disabled={!(status == "INITIAL")} type="submit">
            {" "}
            {status == "LOADING" ? (
              <div className="buttonloader">
                <CircularProgress />
                LOADING...
              </div>
            ) : (
              "Calculate wall"
            )}{" "}
          </button>
          <br />
          <br />
          {url != "" ? (
            <a href={url}>Download project</a>
          ) : (
            "Fill in the form and submit the form... "
          )}
        </form>
      </div>
    </div>
  );
};

export default WallConfigurator;
